export default [
  {
    path: '/deviceManagement',
    name: 'deviceManagement',
    component: () => import(/* webpackChunkName: "deviceManagement" */ './index'),
    meta: {
      type: 'deviceManagement'
    },
    redirect: '/deviceManagement/deviceList',
    children: [
      {
        path: 'deviceList',
        component: () => import(/* webpackChunkName: "appManagement" */ './deviceList'),
        redirect: '/deviceManagement/deviceList/index',
        children: [
          {
            path: 'index',
            name: 'deviceList',
            component: () => import(/* webpackChunkName: "appManagement" */ './deviceList/views')
          },
          {
            path: 'deviceListDetail/:sn/:model',
            name: 'deviceListDetail',
            component: () =>
              import(/* webpackChunkName: "appManagement" */ './deviceList/views/deviceListDetail')
          },
          {
            path: 'deviceListLog/:sn/:model/:version',
            name: 'deviceListLog',
            component: () =>
              import(/* webpackChunkName: "appManagement" */ './deviceList/views/deviceListLog')
          },
          {
            path: 'deviceListTask/:sn/:model',
            name: 'deviceListTask',
            component: () =>
              import(/* webpackChunkName: "deviceManagement" */ './deviceList/views/deviceListTask')
          }
        ]
      },

      {
        path: 'groupManagement',
        name: 'groupManagement',
        component: () => import(/* webpackChunkName: "appManagement" */ './groupManagement')
      }
    ]
  }
]
